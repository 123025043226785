import React from "react"

type Props = {
  text: string
  classes?: string
  type?: "submit" | "button" | "reset" | undefined
  link?: string
  rounded?: boolean
}

const GenericButton = ({ classes = "", text, type = "button", rounded = false, link }: Props) => {
  let buttonClasses =
    classes +
    ` font-roboto bg-blue-landing hover:bg-blue-landing-light text-white py-2 px-2 md:px-5 lg:px-20 2xl:text-2xl`
  buttonClasses += rounded ? ` rounded-full` : ` rounded-md`

  if (link) {
    return (
      <a href={link} type={type || "submit"} className={buttonClasses}>
        {text || "Submit"}
      </a>
    )
  }

  return (
    <button type={type || "submit"} className={buttonClasses}>
      {text || "Submit"}
    </button>
  )
}

export default GenericButton
