import { graphql, useStaticQuery } from "gatsby"

type UseSiteMetadataProps = {
  site: {
    siteMetadata: {
      siteTitle: string
      siteUrl: string
      hideSiteTitle: boolean
      siteDescription: string
      siteLanguage: string
      siteImage: string
      footerTopText: string
      footerCopyrightText: HTMLInputElement
      environment: string
      tealiumUrlProd: string
      tealiumUrlQa: string
      [key: string]: unknown
    }
  }
}

const useSiteMetadata = () => {
  // Guard against an environment where graphql is not accessible
  try {
    const data = useStaticQuery<UseSiteMetadataProps>(graphql`
      query {
        site {
          siteMetadata {
            siteTitle
            siteUrl
            hideSiteTitle
            siteDescription
            siteLanguage
            siteImage
            footerTopText
            footerCopyrightText
            tealiumUrlProd
            tealiumUrlQa
            environment
          }
        }
      }
    `)

    return data.site.siteMetadata
  } catch (ex) {
    if (ex.message.includes(`The result of this StaticQuery could not be fetched`)) {
      console.log(ex)
      return {
        siteTitle: `siteTitle`,
        siteUrl: `siteUrl`,
        hideSiteTitle: false,
        siteDescription: ``,
        siteLanguage: ``,
        siteImage: ``,
        footerTopText: ``,
        footerCopyrightText: ``,
        tealiumUrlProd: undefined,
        tealiumUrlQa: undefined,
        environment: `development`
      }
    }

    throw ex
  }
}

export default useSiteMetadata
