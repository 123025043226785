import queryString from "query-string"
import { LeadType } from "../types"
import sanitizeHtml from "sanitize-html"
import axios from "axios"

const buildCampaignName = function (): string | undefined {
  return process.env.GATSBY_CAMPAIGN_NAME
}

const buildCommunicationCampaignId = function (): string | undefined {
  // This is for future use
  return undefined
}

const buildClientRequestIPAddress = function (): string | undefined {
  return `0.0.0.0`
}

const buildQueryHash = function () {
  const { search } = window.location
  const parsed = queryString.parse(search)

  return {
    FullQueryString: search,
    ...parsed,
  }
}

const getCookie = function (name: string): string | null {
  var decodedCookie = decodeURIComponent(document.cookie);
  var cookies = decodedCookie.split(';');
  for (var i = 0; i < cookies.length; i++) {
      var cookie = cookies[i].trim();
      if (cookie.indexOf(name) == 0) {
          return cookie.substring(name.length, cookie.length); // Return the cookie value
      }
  }

  return null; // Return null if the cookie is not found
}

const buildLeadPost = function (lead: LeadType): any {
  const urlParams = new URLSearchParams(window.location.search);

  const leadsRxCookie = "_lab="; // This is the LeadsRx cookie name

  return {
    ...(lead.firstName && { firstName: sanitizeHtml(lead.firstName) }),
    ...(lead.lastName && { lastName: sanitizeHtml(lead.lastName) }),
    ...(lead.email && { email: sanitizeHtml(lead.email) }),
    ...(lead.phoneNumber && { phoneNumber: sanitizeHtml(lead.phoneNumber) }),
    ...(lead.income && { annualIncome: sanitizeHtml(lead.income) }),
    ...(lead.dateOfBirth && { birthDate: lead.dateOfBirth }),
    ...(lead.state && { state: sanitizeHtml(lead.state) }),
    ...(lead.zipCode && { zipcode: sanitizeHtml(lead.zipCode) }),
    ...(lead.familySize && { familySize: lead.familySize }),


    ...(lead.clickDimensionsUserId && { ClickDimensionsUserId: lead.clickDimensionsUserId }),
    ...(lead.clickDimensionsSessionId && { ClickDimensionsSessionId: lead.clickDimensionsSessionId }),
    ...(lead.googleUserId && { GoogleUserId: lead.googleUserId }),
    ...(lead.webVisits && { WebVisits: lead.webVisits }),
    ...(lead.webPageViews && { WebPageViews: lead.webPageViews }),
    ...(lead.sourceType && { SourceType: lead.sourceType }),
    // @ts-ignore
    ...(lead.firstDate && { FirstDate: lead.firstDate }),
    ...(lead.firstSource && { FirstSource: lead.firstSource }),
    ...(lead.firstMedium && { FirstMedium: lead.firstMedium }),
    ...(lead.firstCampaign && { FirstCampaign: lead.firstCampaign }),
    ...(lead.landingPageFull && { LandingPageFull: lead.landingPageFull }),
    ...(lead.sourceReferrer && { SourceReferrer: lead.sourceReferrer }),
    ...(lead.landingPage && { LandingPage: lead.landingPage }),

    ...({ medium: urlParams.get('utm_medium') }),
    ...({ campaign: urlParams.get('utm_campaign') }),
    ...({ adgroup: urlParams.get('adgroup') }),
    ...({ term: urlParams.get('utm_term') }),
    ...({ GoogleAdGroupId: urlParams.get('adgroupid') }),
    ...({ GoogleCampaignId: urlParams.get('campaignid') }),
    ...({ ClickId: urlParams.get('msclkid') || urlParams.get('gclid') }),
    ...({ GoogleTargetId: urlParams.get('targetid') }),
    ...({ GoogleLocInterestMs: urlParams.get('loc_interest_ms') }),
    ...({ GoogleLocPhysicalMs: urlParams.get('loc_physical_ms') }),
    ...({ GoogleMatchType: urlParams.get('matchtype') }),
    ...({ GoogleDevice: urlParams.get('device') }),
    ...({ GoogleCreative: urlParams.get('creative') }),
    ...({ Keyword: urlParams.get('keyword') }),
    ...({ GooglePlacement: urlParams.get('placement') }),
    ...({ GoogleAdPosition: urlParams.get('adposition') }),
    ...({ Source: urlParams.get('utm_source') }),
    ...({ SourceDetails: lead.leadSourceDomain?.replace(`https://`, ``) }),
    ...({ SourceName: lead.leadSourceDomain?.replace(`https://`, ``) }),

    ...(lead.leadSourceDomain && { LeadSourceDomain: lead.leadSourceDomain?.replace(`https://`, ``) }), // Make sure this domain gets sent without protocol
    CampaignName: buildCampaignName(),
    CommunicationCampaignId: buildCommunicationCampaignId(),
    ipaddress: buildClientRequestIPAddress(),
    fullQueryString: buildQueryHash().FullQueryString,
    leadsRxCookieId: getCookie(leadsRxCookie),
    ...(lead.environment && { environment: lead.environment }),
  }
}

export const postLead = async function (lead: LeadType): Promise<any> {
  const data = buildLeadPost(lead);
  const w = window as any;

  if (typeof window !== `undefined` && typeof w.datadogLogs !== `undefined`) {
    w.datadogLogs.logger.info(`track lead:`, data)
  }

  try {
    const response = await fetch(`/.netlify/functions/salesforce-post`, {
      method: `POST`,
      body: JSON.stringify(data),
    })

    if (response.status < 200 || response.status > 299) {
      w.datadogLogs.logger.error(`bad response for LeadPost Salesforce:`, response.status);
      throw new Error(`bad response for LeadPost Salesforce: ${response.status}`)
      return undefined
    }
  
    const body = (await response.text()) || '';
    if (!(body?.length > 0)) {
      w.datadogLogs?.logger.error(`submit salesforce error:`, body)
      throw new Error(`submit salesforce error: ${body}`);
      return undefined;
    }
  
    return body
  } catch (err) {
    w.datadogLogs.logger.error(`submit salesforce error:`, (err as Error)?.message);
    console.log(`Leads.ts:150 ->`, err);
    throw new Error(`submit salesforce error: ${(err as Error)?.message}`);
    return undefined;
  }

  return true
}
