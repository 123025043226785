import React from "react"
import IcoMoon from "react-icomoon"
const iconSet = require("./icomoon-selection.json")

const Icon = ({ ...props }) => {
  // make sure it is added to icomoon-selection.json
  const icon = iconSet.icons.find((thisIcon: { properties: { name: any; }; }) => thisIcon.properties.name === props.icon)

  if (!icon) {
    throw new Error(`IcoMoon icon ${props.icon} not found in icomoon-selection.json.  Add it from the reference selection.json`)
  }

  return <IcoMoon iconSet={iconSet} {...props} />;
};

export default Icon;
