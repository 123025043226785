import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import queryBannerImage from "../../../hooks/query-banner-image"
import replaceSlashes from "../../../../utils/replace-slashes"
import useSiteMetadata from "../../../hooks/use-site-metadata"
import useMinimalConfig from "../../../hooks/use-minimal-config"
import { isDesktopScreen } from "../../../../utils/measure-screen-size"

type Props = {
  logo: string
  logoLink: string
  desktopHeight: number
  mobileHeight: number
}

const HeaderTitle = ({ logo, logoLink, desktopHeight, mobileHeight }: Props) => {
  const { siteImage, siteTitle, hideSiteTitle } = useSiteMetadata()
  const { basePath } = useMinimalConfig()
  const logoImage = queryBannerImage(logo || siteImage)
  const [height, setHeight] = useState(mobileHeight)
  const logoImageAspectRatio = logoImage ? logoImage?.node?.gatsbyImageData.width / logoImage?.node?.gatsbyImageData.height : 1

  useEffect(() => {
    setHeight(isDesktopScreen() ? desktopHeight : mobileHeight)
  })

  return (
    <React.Fragment>
      <a href={logoLink || `/${basePath}`} aria-label={`${siteTitle} - Back to home`} className="text-header no-underline flex-0 my-auto">
        <span className="sr-only">{siteTitle}</span>
        {logoImage ? (
          <div style={{ height: `${height}px`, width: `${height * logoImageAspectRatio}px` }}>
            <GatsbyImage image={getImage(logoImage?.node)} style={{ height: `100%`, width: `100%` }} imgStyle={{ objectFit: "contain" }} alt={siteTitle} />
          </div>
        ) : (
          <img
            src={siteImage}
            style={{
              height: `${height}px`,
            }}
            alt={siteTitle}
          />
        )}
      </a>

      {siteTitle && !hideSiteTitle && (
        <a href={logoLink || `/${basePath}`} aria-label={`${siteTitle} - Back to home`} className="text-header no-underline flex-0 my-auto">
          <h1 className="float-left mx-1 text-sm md:text-2xl lg:text-3xl">{siteTitle}</h1>
        </a>
      )}
    </React.Fragment>
  )
}

export default HeaderTitle
