import React from "react"
import { GatsbyImage, getImage, withArtDirection } from "gatsby-plugin-image"
import queryHeroImage from "../../../hooks/query-hero-image"

type Props = {
  url: string
  mobileUrl: string
  height: string
  backgroundPosition: string
  backgroundColor: string
  backgroundFit: string
}

const BackgroundImage = ({ url, mobileUrl, height, backgroundPosition, backgroundColor, backgroundFit }: Props) => {
  if (!url) {
    return null
  }

  const bgImage = queryHeroImage(url)
  const bgImageSm = queryHeroImage(mobileUrl || url)
  
  if (!bgImage) {
    return (
      <img
        src={url}
        style={{
          position: `absolute`,
          top: `0px`,
          left: `0px`,
          width: `100%`,
          height: `100%`,
          zIndex: -1,
          objectFit: backgroundFit || `cover`,
          objectPosition: backgroundPosition || `center bottom`,
          backgroundColor: backgroundColor || `#fff`,
        }}
        alt=""
      />
    )
  }

  const images = withArtDirection(getImage(bgImage?.node), [
    {
      media: "(max-width: 720px)",
      image: getImage(bgImageSm?.node || bgImage?.node),
    },
  ])

  return (
    <GatsbyImage
      image={images}
      style={{
        position: `absolute`,
        top: `0px`,
        left: `0px`,
        width: `100%`,
        height: `100%`,
        zIndex: -1,
        backgroundColor: backgroundColor || `#fff`,
      }}
      imgStyle={{ objectPosition: backgroundPosition || `center bottom`, objectFit: backgroundFit || `cover`, height: height || `100%` }}
      alt=""
    />
  )
}

export default BackgroundImage
