import React, { useState, useRef } from "react"
import Datepicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"

type Props = {
  initialDate: Date | undefined
  maxDate: Date | undefined
  onChange: Function | undefined
  onTab: Function | undefined
  [key: string]: any
}

const BirthdayPicker = ({ initialDate, maxDate, onChange, onTab, ...props }: Props) => {
  const [startDate, setStartDate] = useState(initialDate)
  const pickerRef = useRef<Datepicker>()

  return (
    <Datepicker
      ref={pickerRef}
      selected={startDate}
      onChange={(date: Date) => {
        setStartDate(date)
        if (onChange) onChange(date)
      }}
      onKeyDown={(e) => {
        if (e.keyCode === 9) {
          e.preventDefault()
          pickerRef.current.setOpen(false)
          if (onTab) onTab(e)
        }
      }}
      maxDate={maxDate || new Date()}
      peekNextMonth
      showMonthDropdown
      showYearDropdown
      disabledKeyboardNavigation
      dropdownMode="select"
      {...props}
    />
  )
}

export default BirthdayPicker
