import React from "react"
import loadable from "@loadable/component"
import { HeaderType } from "../types"

const EmptyHeader = loadable(() => import("./headers/empty"))
const DefaultHeader = loadable(() => import("./headers/default"))
const DefaultRelativeHeader = loadable(
  () => import("./headers/default-relative")
)
const PassthroughHeader = loadable(() => import("./headers/passthrough"))
const PassthroughLeftLogoHeader = loadable(
  () => import("./headers/passthrough-left-logo")
)
const PhoneBasicHeader = loadable(() => import("./headers/phone-basic"))
const PhoneBasicHeaderV2 = loadable(() => import("./headers/phone-basic-v2"))
const Complexv1 = loadable(() => import("./headers/complex-v1"))
const EnrollmentBasicHeader = loadable(() => import("./headers/enrollment-basic"))
const PhoneBasicHeaderV3 = loadable(() => import("./headers/phone-basic-v3"))

type LayoutProps = {
  header: HeaderType
  [key: string]: any
}

const Header = ({ header, ...props }: LayoutProps) => {
  switch (header?.templateId || ``) {
    case `empty`:
      return <EmptyHeader header={header} {...props} />
    case `phone-basic`:
      return <PhoneBasicHeader header={header} {...props} />
    case `phone-basic-v2`:
      return <PhoneBasicHeaderV2 header={header} {...props} />
    case `default-relative`:
      return <DefaultRelativeHeader header={header} {...props} />
    case `passthrough`:
      return <PassthroughHeader header={header} {...props} />
    case `passthrough-left`:
      return <PassthroughLeftLogoHeader header={header} {...props} />
    case `complex-v1`:
      return <Complexv1 header={header} {...props} />
    case `enrollment-basic`:
        return <EnrollmentBasicHeader header={header} {...props} />
    case `phone-basic-v3`:
      return <PhoneBasicHeaderV3 header={header} {...props} />
    default:
      return <DefaultHeader header={header} {...props} />
  }
}

export default Header
