
type cookie = {
    cookie:string
}
export const trimmCookieValue = (cookie:cookie, index:number) => {
    const CookieValue = document.cookie?.split(`; `)?.find(row => row.startsWith(cookie+"="))?.split(`=`)[1];

    // Take the last two array items

    let output = {};
    CookieValue?.split(/\s*;\s*/).forEach(function(pair) {
        // @ts-ignore
        pair = pair.split(/\s*=\s*/);
        let name = decodeURIComponent(pair[0]);
        // var value = decodeURIComponent(pair.splice(1).join('='));
        // @ts-ignore
        output['value'] = name;
    });
    // @ts-ignore
    let out =output['value']?.split("|||")
    if(out !== undefined){
        return out[index]?.split(/=(.+)/)[1]
    }else{
        return ""
    }
}
