import sanitizeHtml from "sanitize-html"

export const createMarkup = (text: string) => {
  return { 
    __html: sanitizeHtml(text, {
      allowedTags: [
        "address", "article", "aside", "footer", "header",
        "h1", "h2", "h3", "h4", "h5", "h6", "hgroup",
        "main", "nav", "section",
        // Text content
        "blockquote", "dd", "div", "dl", "dt", "figcaption", "figure",
        "hr", "li", "main", "ol", "p", "pre", "ul",
        // Inline text semantics
        "a", "abbr", "b", "bdi", "bdo", "br", "cite", "code", "data", "dfn",
        "em", "i", "kbd", "mark", "q",
        "rb", "rp", "rt", "rtc", "ruby",
        "s", "samp", "small", "span", "strong", "sub", "sup", "time", "u", "var", "wbr",
        // Table content
        "caption", "col", "colgroup", "table", "tbody", "td", "tfoot", "th",
        "thead", "tr",
        // Images
        "img", "svg", "path"
      ],
      allowedAttributes: {
        "*": ["class", "style", "id"],
        "a": ["href", "name", "target", "onclick"],
        "img": ["src"],
        "svg": ["viewbox"],
        "path": ["d"]
      },
      lowerCase: false
    }) 
  }
}
