import queryString from "query-string"
import { LeadType } from "../types"
import sanitizeHtml from "sanitize-html"

const buildUniversalIdPost = function (lead: LeadType): any {
  const post = {
    ...(lead.firstName && { first_name: sanitizeHtml(lead.firstName) }),
    ...(lead.lastName && { last_name: sanitizeHtml(lead.lastName) }),
    ...(lead.email && { email: sanitizeHtml(lead.email) }),
    ...(lead.phoneNumber && { phone: sanitizeHtml(lead.phoneNumber) }),
    ...(lead.dateOfBirth && { dob: (new Date(lead.dateOfBirth)).toISOString() }),
    ...(lead.state && { state_code: sanitizeHtml(lead.state) }),
    ...(lead.income && { annualIncome: sanitizeHtml(lead.income) }),
    ...(lead.zipCode && { zip: sanitizeHtml(lead.zipCode) }),
    source_system_code: `crm_lead`,
  }

  return post
}

export const getUniversalId = async function (lead: LeadType): Promise<string | undefined> {
  const data = buildUniversalIdPost(lead)

  console.log('Data from Universal-Id...')
  console.log(data)

  try {
    const response = await fetch(`/.netlify/functions/universal-id-post`, {
      method: `POST`,
      body: JSON.stringify(data),
    })
  
    const body = await response.text()
  
    if (response.status < 200 || response.status > 299) {
      console.log(`bad response for getUniversalId ${response.status}`)
      window.datadogLogs.logger.error(`submit universal id error:`, body)
      return undefined
    }
  
    return body
  }
  catch (err) {
    window.datadogLogs?.logger.error(`submit universal id error:`, body)
  }

  return undefined
}
