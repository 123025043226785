import React from "react"
import keyGenerator from "../../../utils/key-generator"

export interface GridProps {
  children: JSX.Element[] | JSX.Element
  className?: string
}
export interface GridChildProps extends GridProps {
  size: string
  id?: string
}
export const GridChildTypes = {
  FULL: "full",
  MIDDLE: "mid",
}

export function Grid(props: GridProps) {
  return <div className={getGridClassName("grid grid-cols-content grid-cols-6 max-w-full", props.className)}>{props.children}</div>
}
// Add a child in the middle column
export function GridChildContent(props: GridChildProps) {
  const id = props.id ? props.id : keyGenerator("-grid-section")

  let className = getGridClassName("col-start-1 col-span-6 px-6 lg:col-start-2 lg:col-span-4", props.className) // In the middle, default

  if (props.size == GridChildTypes.FULL) {
    className = getGridClassName("col-start-1 col-end-7", props.className)
  }

  return (
    <div id={id} className={className}>
      {props.children}
    </div>
  )
}
//Filtrates the className so if it's undefined it return the original className
function getGridClassName(originalClassName: string, addedClassName: string | undefined): string {
  return addedClassName ? originalClassName + " " + addedClassName : originalClassName
}
