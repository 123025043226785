const ranges = [
  { state: `AK`, start: 99501, end: 99950 },
  { state: `AL`, start: 35004, end: 36925 },
  { state: `AR`, start: 71601, end: 72959 },
  { state: `AR`, start: 75502, end: 75502 },
  { state: `AZ`, start: 85001, end: 86556 },
  { state: `CA`, start: 90001, end: 96162 },
  { state: `CO`, start: 80001, end: 81658 },
  { state: `CT`, start: 6001, end: 6389 },
  { state: `CT`, start: 6401, end: 6928 },
  { state: `DC`, start: 20001, end: 20039 },
  { state: `DC`, start: 20042, end: 20599 },
  { state: `DC`, start: 20799, end: 20799 },
  { state: `DE`, start: 19701, end: 19980 },
  { state: `FL`, start: 32004, end: 34997 },
  { state: `GA`, start: 30001, end: 31999 },
  { state: `GA`, start: 39901, end: 39901 },
  { state: `HI`, start: 96701, end: 96898 },
  { state: `IA`, start: 50001, end: 52809 },
  { state: `IA`, start: 68119, end: 68120 },
  { state: `ID`, start: 83201, end: 83876 },
  { state: `IL`, start: 60001, end: 62999 },
  { state: `IN`, start: 46001, end: 47997 },
  { state: `KS`, start: 66002, end: 67954 },
  { state: `KY`, start: 40003, end: 42788 },
  { state: `LA`, start: 70001, end: 71232 },
  { state: `LA`, start: 71234, end: 71497 },
  { state: `MA`, start: 1001, end: 2791 },
  { state: `MA`, start: 5501, end: 5544 },
  { state: `MD`, start: 20331, end: 20331 },
  { state: `MD`, start: 20335, end: 20797 },
  { state: `MD`, start: 20812, end: 21930 },
  { state: `ME`, start: 3901, end: 4992 },
  { state: `MI`, start: 48001, end: 49971 },
  { state: `MN`, start: 55001, end: 56763 },
  { state: `MO`, start: 63001, end: 65899 },
  { state: `MS`, start: 38601, end: 39776 },
  { state: `MS`, start: 71233, end: 71233 },
  { state: `MT`, start: 59001, end: 59937 },
  { state: `NC`, start: 27006, end: 28909 },
  { state: `ND`, start: 58001, end: 58856 },
  { state: `NE`, start: 68001, end: 68118 },
  { state: `NE`, start: 68122, end: 69367 },
  { state: `NH`, start: 3031, end: 3897 },
  { state: `NJ`, start: 7001, end: 8989 },
  { state: `NM`, start: 87001, end: 88441 },
  { state: `NV`, start: 88901, end: 89883 },
  { state: `NY`, start: 6390, end: 6390 },
  { state: `NY`, start: 10001, end: 14975 },
  { state: `OH`, start: 43001, end: 45999 },
  { state: `OK`, start: 73001, end: 73199 },
  { state: `OK`, start: 73401, end: 74966 },
  { state: `OR`, start: 97001, end: 97920 },
  { state: `PA`, start: 15001, end: 19640 },
  { state: `RI`, start: 2801, end: 2940 },
  { state: `SC`, start: 29001, end: 29948 },
  { state: `SD`, start: 57001, end: 57799 },
  { state: `TN`, start: 37010, end: 38589 },
  { state: `TX`, start: 73301, end: 73301 },
  { state: `TX`, start: 75001, end: 75501 },
  { state: `TX`, start: 75503, end: 79999 },
  { state: `TX`, start: 88510, end: 88589 },
  { state: `UT`, start: 84001, end: 84784 },
  { state: `VA`, start: 20040, end: 20041 },
  { state: `VA`, start: 20040, end: 20167 },
  { state: `VA`, start: 20042, end: 20042 },
  { state: `VA`, start: 22001, end: 24658 },
  { state: `VT`, start: 5001, end: 5495 },
  { state: `VT`, start: 5601, end: 5907 },
  { state: `WA`, start: 98001, end: 99403 },
  { state: `WI`, start: 53001, end: 54990 },
  { state: `WV`, start: 24701, end: 26886 },
  { state: `WY`, start: 82001, end: 831286 },
]

function lookUpState(zipCode: string | null): string {
  const formattedZip: number = parseInt(`${zipCode}`, 10)
  const range = ranges.find((r) => r.start <= formattedZip && r.end >= formattedZip)

  return range?.state || ``
}

export default lookUpState
