import React from "react"
import { LinkType } from "../../../types"
import Icon from "../../icons"
import { createMarkup } from "../../../../utils/markup-creator"

type ExternalLinksProps = {
  links: [LinkType]
  [key: string]: any
}

const HeaderExternalLinks = ({ links, ...props }: ExternalLinksProps) => (
  <React.Fragment>
    {links &&
      links.map((link) => (
        <a
          key={link.url}
          href={link.url}
          className={`${link.class || `border border-primary text-primary rounded-full`
            } flex items-center justify-center h-full text-center tracking-navButton max-w-max px-2 py-1 text-xs md:justify-start md:px-navButton md:py-2 md:text-xl md:min-w-navButton`}

        >
          {link.icon && (
            <span className="pr-1 hidden md:inline">
              <Icon icon={link.icon} size={32} />
            </span>
          )}
          <span dangerouslySetInnerHTML={createMarkup(link.name)}></span>
        </a>
      ))}
  </React.Fragment>
)

export default HeaderExternalLinks
