import React from "react"
import { SectionType } from "../../types"

export const SpacerSizes = {
  X_SMALL: "h-2 md:h-4",
  SMALL: "h-4 md:h-8",
  MEDIUM: "h-8 md:h-16",
  BIG: "h-12 md:h-24",
}

type SectionProps = {
  section: SectionType
  size?: string
  index: any
  [key: string]: any
}

const SpacerSection = ({ section, size = SpacerSizes.MEDIUM }: SectionProps) => {
  return <div className={`w-full ${size} ${section.advanced?.class || ``}`} />
}

export default SpacerSection
