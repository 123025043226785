import { graphql, useStaticQuery } from "gatsby"

const queryHeroImage = (imageUrl: string | undefined) => {
  if (!imageUrl) {
    return undefined
  }

  // Guard against an environment where graphql is not accessible
  try {
    const data = useStaticQuery(graphql`
      query {
        allImageSharp {
          edges {
            node {
              gatsbyImageData(layout: FULL_WIDTH, placeholder: DOMINANT_COLOR)
            }
          }
        }
      }
    `)

    return data.allImageSharp.edges.find((edge) => {
      return edge.node.gatsbyImageData.images.fallback.src.toLowerCase().endsWith(imageUrl.split(`/`).pop()?.toLowerCase())
    })
  } catch (ex) {
    if (ex.message.includes(`The result of this StaticQuery could not be fetched`)) {
      return undefined
    }

    throw ex
  }
}

export default queryHeroImage