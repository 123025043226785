import React, { useState } from "react"
import { useForm } from "react-hook-form"
import { format } from "date-fns"
import queryString from "query-string"
import BirthdayPicker from "./components/birthday-picker"
import Spinner from "./components/spinner"
import { FormType } from "../../types"
import { postLead } from "../../api/leads_v2"
import { getUniversalId } from "../../api/uinversal-id"
import useSiteMetadata from "../../hooks/use-site-metadata"
import lookUpState from "../../../utils/look-up-state"
import { trimmedGaCookieValue } from "../../../utils/look-up-ga-cookie"
import { trimmCookieValue } from "../../../utils/look-up-sbjs"

type FormProps = {
    form: FormType
    [key: string]: any
}

type FormData = {
    firstName: string
    lastName: string
    email: string
    phoneNumber: string
    income: string
    household: string
    state: string
    zipCode: string
    dateOfBirth: string
    clickDimensionsUserId: string
    clickDimensionsSessionId: string
    googleUserId: string
    webVisits: string
    webPageViews: string
    sourceType: string
    firstDate: string
    firstSource: string
    firstMedium: string
    firstCampaign: string
    landingPageFull: string
    sourceReferrer: string
}


const QuoteToolV2 = ({ form, ...props }: FormProps) => {
    const search = props?.location?.search
    const parsedSearch = queryString.parse(search)
    const { siteUrl, environment } = useSiteMetadata()
    const [stateData, setData] = useState({
        isSubmitting: false,
        zipCode: parsedSearch.zipCode ? `${parsedSearch.zipCode}` : ``,
        state: lookUpState(`${parsedSearch.zipCode || `0`}`),
    })
    const [clickDimensionsUserId, setclickDimensionsUserId] = useState('')
    const [clickDimensionsSessionId, setclickDimensionsSessionId] = useState('')
    const [googleUserId, setgoogleUserId] = useState('')
    const [webVisits, setwebVisits] = useState('')
    const [webPageViews, setwebPageViews] = useState('')
    const [sourceType, setsourceType] = useState('')
    const [firstDate, setfirstDate] = useState('')
    const [firstSource, setfirstSource] = useState('')
    const [firstMedium, setfirstMedium] = useState('')
    const [firstCampaign, setfirstCampaign] = useState('')
    const [landingPageFull, setlandingPageFull] = useState('')
    const [sourceReferrer, setsourceReferrer] = useState('')
    const [landingPage, setlandingPage] = useState('')

    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm()
    const firstNameClasses = `h-12 mb-1 p-3  block w-full bg-white border-gray-200 focus:ring-0 ${errors.firstName ? `border-red-500` : ``}`
    const lastNameClasses = `h-12 mb-1 p-3 block w-full bg-white  border-gray-200 rounded-none focus:ring-0 ${errors.lastName ? `border-red-500` : ``}`
    const emailClasses = `h-12 mb-1 p-3 block w-full md:col-span-2 bg-white border-gray-200 rounded-none focus:ring-0 ${errors.email ? `border-red-500` : ``}`
    const phoneNumberClasses = `h-12 mb-1 p-3 block w-full bg-white border-gray-200 rounded-none focus:ring-0 ${errors.phoneNumber ? `border-red-500` : ``}`
    const incomeClasses = `h-12 mb-1 p-3 block w-full bg-white border-gray-200 rounded-none focus:ring-0 ${errors.income ? `border-red-500` : ``}`
    const stateClasses = `h-12 mb-1 p-3 block w-full bg-white border-gray-200 rounded-none focus:ring-0 ${errors.state ? `border-red-500` : ``}`
    const zipCodeClasses = `h-12 mb-1 p-3 block w-full bg-white border-gray-200 rounded-none focus:ring-0 ${errors.zipCode ? `border-red-500` : ``}`
    const dateOfBirthClasses = `h-12 mb-1 p-3 block w-full  bg-white border-gray-200 rounded-none focus:ring-0 ${errors.dateOfBirth ? `border-red-500` : ``}`
    const consentClasses = `mt-2 ${errors.consent ? `border-red-500` : ``}`
    const buttonClasses = `h-12 p-3 w-1/1  button ${stateData.isSubmitting ? `disabled` : `primary`}`

    const wrapGetUniversalId = async (data: FormData): Promise<string | undefined> => {
        try {
            const universalId = await getUniversalId({
                firstName: data.firstName,
                lastName: data.lastName,
                email: data.email,
                phoneNumber: data.phoneNumber,
                income: '0',
                state: data.state,
                zipCode: data.zipCode,
                dateOfBirth: data.dateOfBirth,
                familySize: Number(data.familySize),
                leadSourceDomain: siteUrl,
                environment: environment,
            })

            return universalId
        } catch (err) {
            window.datadogLogs?.logger.error(`submit universal id error:`, body)
        }

        return undefined
    }

    const onSubmit = async (data: FormData) => {
        setData({ ...stateData, isSubmitting: true })
        window.sessionStorage.setItem('firstName', data.firstName)
        window.sessionStorage.setItem('lastName', data.lastName)
        window.sessionStorage.setItem('email', data.email)
        window.sessionStorage.setItem('dateOfBirth', data.dateOfBirth)
        window.sessionStorage.setItem('familySize', data.household)
        window.sessionStorage.setItem('phoneNumber', data.phoneNumber)
        try {
            const result = await postLead({
                firstName: data.firstName,
                lastName: data.lastName,
                email: data.email,
                phoneNumber: data.phoneNumber,
                income: '0',
                state: data.state,
                zipCode: data.zipCode,
                dateOfBirth: data.dateOfBirth,
                familySize: Number(data.household !== 'family' ? data.household : '3'),
                leadSourceDomain: siteUrl,
                environment: environment,
                clickDimensionsUserId: clickDimensionsUserId,
                clickDimensionsSessionId: clickDimensionsSessionId,
                googleUserId: googleUserId,
                webVisits: webVisits,
                webPageViews: webPageViews,
                sourceType: sourceType,
                firstDate: firstDate,
                firstSource: firstSource,
                firstMedium: firstMedium,
                firstCampaign: firstCampaign,
                landingPageFull: landingPageFull,
                sourceReferrer: sourceReferrer,
                landingPage: landingPage
            })

            if (result) {
                // First get a universal id for this lead, so it can be tracked with analytics
                const universalId = await wrapGetUniversalId(data)

                // GA event logging
                if (typeof marketingDataLayer !== `undefined`) {
                    marketingDataLayer?.push({
                        universalID: universalId,
                    })

                    marketingDataLayer?.push({
                        event: `gaForm`,
                        eventCategory: `Form`,
                        eventAction: `quote-tool-v2`,
                        eventLabel: location.pathname,
                    })
                }

                // Tealium event logging
                if (typeof utag !== `undefined`) {
                    console.log(`Tealium is loaded`)

                    utag.link({
                        customer_birthdate: "", //Required
                        customer_email: `${data.email}`, //Required
                        customer_first_name: `${data.firstName}`, //Required
                        customer_id: trimmedGaCookieValue(), //Required (RTRIM value of _ga Cookie)
                        customer_last_name: `${data.lastName}`, //Required
                        customer_name: "", //Required
                        customer_phone_number: `${data.phoneNumber}`, //Required
                        customer_postal_code: `${data.zipCode}`, //Required
                        customer_state: `${data.state}`, //Required
                        customer_type: "family", //Required
                        universal_id: universalId,
                        form_id: "", //Required
                        form_name: "Inquiry Form", //Required
                        tealium_event: "form_complete", //Required
                    })
                }

                location.assign(`${form.destination}`)
            }

            setData({ ...stateData, isSubmitting: false })
        } catch (err) {
            setData({ ...stateData, isSubmitting: false })
            alert(`Your request was not sent successfully.  ${err.message}`)
        }
    }

    function getPathFromUrl(url: string) {
        return url.split(/[?#]/)[0];
    }

    // Configure form values and validation on form register
    React.useEffect(() => {
        register(`dateOfBirth`, { required: true })

        const state = lookUpState(`${parsedSearch.zipCode || `0`}`)

        setData({
            ...stateData,
            state: state,
        })
        setValue(`state`, state)
    }, [register])

    React.useEffect(() => {
        // @ts-ignore
        setclickDimensionsUserId(document.cookie?.split(`; `)?.find(row => row.startsWith(`cuvid=`))?.split(`=`)[1]);
        // @ts-ignore
        setclickDimensionsSessionId(document.cookie?.split(`; `)?.find(row => row.startsWith(`cusid=`))?.split(`=`)[1]);
        // @ts-ignore
        setgoogleUserId(document.cookie?.split(`; `)?.find(row => row.startsWith(`_ga=`))?.split(`=`)[1]);
        // @ts-ignore
        const sbjs_first_add = trimmCookieValue(`sbjs_first_add`, 0);
        // @ts-ignore
        setwebVisits(trimmCookieValue("sbjs_udata", 0));
        // @ts-ignore
        setwebPageViews(trimmCookieValue(`sbjs_session`, 0));
        // @ts-ignore
        setsourceType("Web Form");
        // @ts-ignore
        setfirstDate( new Date(isNaN(sbjs_first_add) ? 0 : Number(sbjs_first_add)).toISOString());
        // @ts-ignore
        setfirstSource(trimmCookieValue(`sbjs_first`, 1));
        // @ts-ignore
        setfirstMedium(trimmCookieValue(`sbjs_first`, 2));
        // @ts-ignore
        setfirstCampaign(trimmCookieValue(`sbjs_first`, 3));
        // @ts-ignore
        setlandingPageFull(trimmCookieValue(`sbjs_current_add`, 1));
        // @ts-ignore
        setsourceReferrer(trimmCookieValue(`sbjs_current_add`, 2));
        // @ts-ignore
        setlandingPage(getPathFromUrl(trimmCookieValue(`sbjs_current_add`, 1)));
    }, [])

    return (
        <div className="max-w-screen-sm mx-auto px-4 sm:px-6 lg:px-0">
            <form id="lead-form" onSubmit={handleSubmit(onSubmit)}>
                <div className="grid sm:grid-cols-1 md:grid-cols-2 gap-3">
                    <input {...register("firstName", { required: true })} style={{ borderRadius: "0 !important" }}
                        type="text" className={firstNameClasses}
                        placeholder="First Name" autoComplete="given-name" autoFocus />

                    <input {...register("lastName", { required: true })} type="text" className={lastNameClasses}
                        placeholder="Last Name" autoComplete="family-name" />

                    <input {...register("email", { required: true })} type="email" className={emailClasses}
                        placeholder="Email" autoComplete="email" />

                    <input {...register("phoneNumber", { required: true, maxLength: 10, minLength: 10 })} type="tel"
                        className={phoneNumberClasses}
                        placeholder="Phone" autoComplete="tel" />

                    <select {...register("household", { required: true })}
                        className={incomeClasses}
                        placeholder="Family size">
                        <option value={""} disabled selected hidden>Family size</option>
                        {/*<option value="" disabled>Family size</option>*/}
                        <option value="1">Yourself</option>
                        <option value="2">You & a dependent</option>
                        <option value="family">Family</option>
                    </select>

                    <input
                        {...register("zipCode", { required: true, maxLength: 5, minLength: 4 })}
                        type="number"
                        className={zipCodeClasses}
                        placeholder="Zip Code"
                        autoComplete="postal-code"
                        defaultValue={stateData.zipCode}
                        value={stateData.zipCode}
                        onChange={(e: { target: { value: any } }) => {
                            setData({ ...stateData, zipCode: e.target.value })
                            setValue('zipCode', e.target.value)
                        }}
                    />

                    <BirthdayPicker
                        name="dateOfBirth"
                        initialDate={undefined}
                        maxDate={new Date().setFullYear(new Date().getFullYear() - 18)}
                        className={dateOfBirthClasses}
                        placeholderText="Birth Date (mm/dd/yyyy)"
                        autoComplete="bday"
                        onChange={(date: Date) => {
                            const value = date ? format(date, `MM/dd/yyyy`) : ``
                            setValue(`dateOfBirth`, value)
                        }}
                        onTab={(e: Event) => {
                            if (e.shiftKey) {
                                document.querySelector(`input[name=zipCode]`)?.focus();
                            } else {
                                document.querySelector(`input[name=consent]`)?.focus();
                            }
                        }}
                    />
                </div>

                <div className="block">
                    <div className="mt-6">
                        <div>
                            <label className="inline-flex items-top">
                                <input {...register("consent", { required: true })} type="checkbox"
                                    className={consentClasses} />
                                <span className="ml-2 text-xs text-white">
                                    By clicking "{form.submitText || `Submit`}" below, I agree to receive calls and text messages (including by autodialer and prerecorded message) from {siteUrl} regarding health coverage at my
                                    phone number above. This consent does not require me to purchase anything.
                                </span>
                            </label>
                        </div>
                    </div>
                </div>

                <div className="text-center mt-5">
                    {Object.keys(errors).length > 0 && (
                        <div
                            className="bg-red-400 p-3 my-10 text-white rounded">{(errors.consent && Object.keys(errors).length === 1 && `Please accept the terms.`) || `Please fix errors in your form.`}</div>
                    )}

                    <button type="submit" className={buttonClasses} disabled={stateData.isSubmitting}
                        style={{
                            fontFamily: 'Montserrat',
                            // fontWeight: '700',
                            // backgroundColor: '#F3B220',
                            // borderColor: '#F3B220'
                        }}>
                        {stateData.isSubmitting &&
                            <Spinner className="float-left h-7 w-5 text-white" size={5} color="white" />}
                        {form.submitText || `Submit`}
                    </button>
                </div>
            </form>
        </div>
    )
}

export default QuoteToolV2
